<template>
  <div>
    <v-navigation-drawer
      v-model="propDrawer"
      fixed
      color="white"
      right
      floating
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'"
      transition="fade-transition"
    >
      <div class="pa-4 d-flex justify-end">
        <v-icon
          color="onSurface_normal"
          size="20"
          @click="$emit('update:drawer', false)"
        >
          mdi-close
        </v-icon>
      </div>
      <v-list flat class="pa-0">
        <v-list-group
          v-for="item in contents"
          :key="item.title"
          :append-icon="item.subContent ? 'mdi-chevron-down' : ''"
        >
          <template v-slot:activator>
            <v-list-item-content
              @click="
                item.externalLink
                  ? openLink(item.externalLink)
                  : item.link
                  ? routerPush(item.link)
                  : ''
              "
              class="py-3"
            >
              <v-list-item-title class="h3 bold onSurface_normal--text">
                {{ $t("pageAppbar." + item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <div v-if="item.subContent" class="py-1">
            <v-list-item
              v-for="subContent in item.subContent"
              :key="subContent.title"
            >
              <v-list-item-content
                @click="
                  subContent.externalLink
                    ? openLink(subContent.externalLink)
                    : subContent.link
                    ? routerPush(subContent.link)
                    : ''
                "
                class="px-4 py-0"
                style="height: 40px"
              >
                <span>
                  <v-list-item-title class="title-1 bold">
                    {{ $t("pageAppbar." + subContent.title) }}
                  </v-list-item-title>
                </span>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    propDrawer: Boolean,
    contents: Array,
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
    routerPush(routerName) {
      if (routerName === "") {
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0))
          .then(() => {
            this.$emit("update:drawer", false);
          });
      }
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style scoped>
::v-deep .v-list-item__icon i {
  font-size: 20px !important;
  color: var(--v-onSurface_normal-base);
}
</style>
