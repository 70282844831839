var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pa-0' : 'px-4 py-0',style:(_vm.$vuetify.theme.dark ? 'background:black' : 'background:white'),attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex py-4",style:(_vm.$vuetify.breakpoint.mdAndUp
        ? ' height: 320px'
        : _vm.$vuetify.breakpoint.smAndUp
        ? 'height: 174px'
        : '')},[_c('div',{staticClass:"mx-auto",class:_vm.$vuetify.breakpoint.mdAndUp
          ? _vm.$vuetify.breakpoint.md && _vm.$i18n.locale != 'ko'
            ? 'px-3 my-auto'
            : 'px-5 my-auto'
          : 'my-0',staticStyle:{"width":"1280px"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mx-auto d-flex"},[_vm._l((_vm.footer),function(data,i){return [_c('div',{key:data.title,staticClass:"d-flex text-left onSurface_light--text px-0",staticStyle:{"flex-direction":"column","width":"140px","gap":"12px"}},[_c('span',{staticClass:"body-1 bold pa-0",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleClick(data)}}},[_vm._v(" "+_vm._s(_vm.$t("pageFooter." + data.title))+" ")]),_vm._l((data.contents),function(item){return _c('span',{key:item.content,staticClass:"body-1",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v(" "+_vm._s(_vm.$t("pageFooter." + item.content))+" ")])})],2),(i != _vm.footer.length - 1)?_c('v-spacer',{key:data.id}):_vm._e()]})],2):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"my-5 onSurface_light",staticStyle:{"border-color":"#dedede"}}):_vm._e(),_c('div',{staticClass:"mx-auto body-2 d-flex onSurface_light--text",staticStyle:{"flex-direction":"column"},style:(_vm.$vuetify.breakpoint.mdAndUp ? 'gap:10px' : 'gap:14px')},[_c('v-img',{attrs:{"max-height":"26px","max-width":"fit-content","contain":"","src":_vm.logoSrc,"position":"center left"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex",style:(_vm.$vuetify.breakpoint.mdAndUp
              ? ''
              : 'flex-direction:column; gap:4px')},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t("pageFooter.company")))]),_c('v-divider',{staticClass:"onSurface_light my-auto",class:_vm.$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2',style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : 'height:10px'),attrs:{"vertical":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("pageFooter.phone")))])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"onSurface_light my-auto",class:_vm.$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2',style:(_vm.$vuetify.breakpoint.mdAndUp ? 'height: 14px' : 'height:10px'),attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t("pageFooter.address")))]),_c('v-divider',{staticClass:"onSurface_light my-auto",class:_vm.$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2',style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : 'height:10px'),attrs:{"vertical":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("pageFooter.registration1")))]),(_vm.$vuetify.breakpoint.lgAndUp || this.$i18n.locale === 'ko')?_c('v-divider',{staticClass:"onSurface_light my-auto",class:_vm.$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2',style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : 'height:10px'),attrs:{"vertical":""}}):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp || this.$i18n.locale === 'ko')?_c('span',[_vm._v(_vm._s(_vm.$t("pageFooter.registration2")))]):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"d-flex",style:(_vm.$vuetify.breakpoint.mdAndUp ? ' ' : 'flex-direction:column;')},[_c('div',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'd-flex align-center' : '',staticStyle:{"gap":"26px"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('span',{staticClass:"body-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openLink(
                      'https://docs.madeall3d.com/660dd9c7-43aa-4824-9eef-e571d143dd2d'
                    )}}},[_vm._v(" "+_vm._s(_vm.$t("pageFooter.privacyPolicy"))+" ")])]),_c('v-divider',{staticClass:"onSurface_light my-auto",class:_vm.$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2',style:(_vm.$vuetify.breakpoint.mdAndUp
                    ? 'height: 14px;'
                    : 'height:10px'),attrs:{"vertical":""}}),_c('div',[_c('span',{staticClass:"body-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openLink(
                      'https://docs.madeall3d.com/99db812a-9497-402b-af98-71b329c04abc'
                    )}}},[_vm._v(" "+_vm._s(_vm.$t("pageFooter.termsOfUse"))+" ")])]),_c('v-divider',{staticClass:"onSurface_light my-auto",class:_vm.$vuetify.breakpoint.mdAndUp ? 'mx-3' : 'mx-2',style:(_vm.$vuetify.breakpoint.mdAndUp ? 'height: 14px' : 'height:10px'),attrs:{"vertical":""}}),_c('div',[_c('span',{staticClass:"body-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openLink(
                      'https://docs.madeall3d.com/a7ed6b0b-92db-4494-adf9-c0a7491c9c1f'
                    )}}},[_vm._v(" "+_vm._s(_vm.$t("pageFooter.marketingTerms"))+" ")])])],1):_vm._e(),_c('div',{staticClass:"d-flex align-center body-2"},[_c('v-icon',{attrs:{"size":"1em","color":"onSurface_light"}},[_vm._v("mdi-copyright")]),_c('span',[_vm._v(" 2023 MadeAll Inc. All rights reserved.")])],1)]),_c('v-spacer',{style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : 'height:14px')}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"pa-0",attrs:{"icon":"","height":"1em","width":"1em","x-small":""},on:{"click":function($event){return _vm.changeDarkMode()}}},[_c('v-icon',{staticClass:"onSurface_light--text",attrs:{"size":"14"}},[_vm._v(" "+_vm._s(_vm.$vuetify.theme.dark ? "mdi-weather-sunny" : "mdi-weather-night")+" ")])],1),_c('v-btn',{staticClass:"ml-2 pa-0",attrs:{"text":"","height":"1em"},on:{"click":function($event){return _vm.changeLang()}}},[_c('span',{staticClass:"body-2 onSurface_light--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t("pageFooter.language"))+" ")])])],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }