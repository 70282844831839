<template>
  <div @mouseover="hover = true" @mouseleave="hover = false">
    <v-app-bar
      style="z-index: 10000"
      hide-on-scroll
      scroll-off-screen
      flat
      :height="$vuetify.breakpoint.mdAndUp ? 60 : 52"
      fixed
      extension-height="170"
      color="white"
      @input="appBarChanged"
    >
      <div
        class="d-flex"
        :class="$vuetify.breakpoint.mdAndUp ? 'px-10 mx-auto' : 'px-4'"
        style="
          max-width: 1280px;
          width: 100%;
          height: 100%;
          justify-content: space-between;
        "
      >
        <div class="d-flex" style="width: 100%">
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            class="hover-pointer my-auto"
            @click="navigateTo('Home')"
            contain
            max-height="36"
            max-width="fit-content"
            :src="logoSrc"
          />
          <v-img
            v-else
            :src="require('@/assets/icons/MadeAll_Icon(Light).svg')"
            max-height="28"
            max-width="28"
            contain
            class="hover-pointer my-auto"
            @click="navigateTo('Home')"
          />
        </div>
        <div
          v-if="$vuetify.breakpoint.lgAndUp"
          class="d-flex ma-0 hover-pointer text-center"
          style="gap: 8px"
        >
          <v-tabs
            background-color="transparent"
            color="transparent"
            slider-color="black"
            height="100%"
            :value="tabIndex"
            optional
          >
            <v-tab
              v-for="(item, n) in contents"
              :key="item.title"
              class="d-flex align-center justify-center mx-1"
              @mouseenter="tabIndex = n"
              @mouseleave="tabIndex = -1"
              @click="handleClick(item)"
              style="width: 140px"
            >
              <span
                class="subtitle-1 bold onSurface_dark--text text-capitalize"
              >
                {{ $t("pageAppbar." + item.title) }}
              </span>
            </v-tab>
          </v-tabs>
        </div>
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex justify-end"
          style="gap: 24px; width: 100%"
        >
          <v-btn
            v-if="
              $store.getters['auth/user/GET_USER'] &&
              $store.getters['auth/user/GET_CLAIMS'].level < 3
            "
            icon
            small
            class="my-auto"
            color="onSurface_dark"
          >
            <v-icon @click="navigateTo('Admin-Orders')">
              mdi-cog-outline
            </v-icon>
          </v-btn>
          <v-menu
            v-if="$store.getters['auth/user/GET_USER']"
            v-model="menu"
            tile
            offset-y
            nudge-left="36px"
            open-on-click
            content-class="elevation-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex" v-bind="attrs" v-on="on">
                <v-avatar size="36" color="transparent" class="my-auto">
                  <v-img
                    :src="
                      $store.getters['auth/user/GET_USER'] &&
                      $store.getters['auth/user/GET_USER'].photoURL
                        ? $store.getters['auth/user/GET_USER'].photoURL
                        : require('@/assets/DefaultProfileImage.svg')
                    "
                    style="border: 2px solid var(--v-black-base)"
                  />
                </v-avatar>
              </div>
            </template>
            <v-card
              v-if="$store.getters['auth/user/GET_USER']"
              tile
              flat
              min-width="280"
              height="170"
              class="d-flex align-center px-9"
              color="white"
            >
              <div
                class="d-flex subtitle-1 regular onSurface_dark--text"
                style="gap: 24px; width: 100%; flex-direction: column"
              >
                <div
                  class="d-flex hover-pointer align-center"
                  style="height: 24px"
                  @click="navigateTo('MyPage')"
                >
                  <span>
                    {{ $t("pageAppbar.myPage") }}
                  </span>
                  <v-spacer />
                  <feather size="20" type="user" color="onSurface_dark" />
                </div>
                <div
                  class="d-flex hover-pointer align-center"
                  style="height: 24px"
                  @click="notReady()"
                >
                  <span>
                    {{ $t("pageAppbar.myMakerSpace") }}
                  </span>
                  <v-spacer />
                  <feather size="20" type="edit" color="onSurface_dark" />
                </div>
                <div
                  class="d-flex hover-pointer align-center"
                  style="height: 24px"
                  @click="logout()"
                >
                  <span>
                    {{ $t("pageAppbar.logout") }}
                  </span>
                  <v-spacer />
                  <feather size="20" type="log-out" color="onSurface_dark" />
                </div>
              </div>
            </v-card>
          </v-menu>

          <div v-else class="d-flex">
            <span
              class="subtitle-1 bold my-auto hover-pointer onSurface_dark--text"
              @click="navigateTo('Login')"
            >
              {{ $t("pageAppbar.login") }}
            </span>
          </div>
          <v-btn
            color="primary"
            height="36"
            style="border-radius: 8px"
            class="subtitle-1 bold my-auto px-3"
            elevation="0"
            @click="navigateTo('Order')"
            :ripple="false"
          >
            <span>{{ $t("pageAppbar.makeOrder") }}</span>
          </v-btn>
        </div>

        <div v-else class="d-flex justify-end" style="gap: 24px; flex: 1">
          <v-btn
            v-if="
              $store.getters['auth/user/GET_USER'] &&
              $store.getters['auth/user/GET_CLAIMS'].level < 3
            "
            icon
            small
            class="my-auto"
            color="onSurface_light"
          >
            <v-icon @click="navigateTo('Admin-Orders')">
              mdi-cog-outline
            </v-icon>
          </v-btn>
          <v-btn
            @click="
              $store.getters['auth/user/GET_USER']
                ? (accountDrawer = !accountDrawer)
                : navigateTo('Login')
            "
            icon
            :ripple="false"
            class="my-auto"
            height="18"
            width="18"
          >
            <v-avatar
              v-if="$store.getters['auth/user/GET_USER']"
              size="26"
              color="transparent"
              class="my-auto"
            >
              <v-img
                :src="
                  $store.getters['auth/user/GET_USER'] &&
                  $store.getters['auth/user/GET_USER'].photoURL
                    ? $store.getters['auth/user/GET_USER'].photoURL
                    : require('@/assets/DefaultProfileImage.svg')
                "
                style="border: 2px solid var(--v-black-base)"
              />
            </v-avatar>
            <feather v-else size="18" type="user" />
          </v-btn>
          <v-btn
            @click="drawer = !drawer"
            icon
            :ripple="false"
            class="my-auto"
            height="18"
            width="18"
          >
            <feather size="18" type="menu" />
          </v-btn>
        </div>
      </div>
      <template v-slot:extension v-if="hover && $vuetify.breakpoint.lgAndUp">
        <div
          style="width: 100%"
          class="align-center justify-center d-flex body-1"
        >
          <div class="d-flex px-2 my-auto" style="gap: 8px">
            <div
              v-for="item in contents"
              :key="item.title"
              style="flex-direction: column; gap: 20px"
              class="pa-0 d-flex text-center"
            >
              <v-hover
                v-for="subContent in item.subContent"
                :key="subContent.title"
              >
                <div
                  style="height: 30px; width: 140px"
                  class="align-center d-flex justify-center"
                >
                  <span
                    class="hover-pointer subtitle-1 regular onSurface_dark--text"
                    @click="handleClick(subContent)"
                  >
                    {{ $t("pageAppbar." + subContent.title) }}
                  </span>
                </div>
              </v-hover>
            </div>
          </div>
        </div>
      </template>
    </v-app-bar>
    <account-nav-drawer
      :contents="contents"
      :propDrawer="accountDrawer"
      v-on:update:drawer="accountDrawer = $event"
      v-if="$vuetify.breakpoint.mdAndUp == false"
    />
    <navigation-drawer
      :contents="contents"
      :propDrawer="drawer"
      v-on:update:drawer="drawer = $event"
      v-if="$vuetify.breakpoint.mdAndUp == false"
    />
    <v-dialog v-model="dialog">
      <v-card
        width="250"
        height="150"
        class="d-flex flex-column align-center pa-5 mx-auto"
      >
        <span class="title-1 bold onSurface_normal--text text-center my-auto">
          로그아웃 하시겠습니까?
        </span>
        <div>
          <v-spacer />
          <v-btn color="primary" text @click="dialog = false"> 돌아가기 </v-btn>
          <v-btn
            color="primary"
            text
            @click="
              dialog = false;
              logout();
            "
          >
            로그아웃
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.hover-pointer:hover {
  cursor: pointer;
}

::v-deep .v-toolbar__content {
  padding: 0px;
}
</style>

<script>
import NavigationDrawer from "@/components/Drawer/NavigationDrawer.vue";
import AccountNavDrawer from "@/components/Drawer/AccountNavDrawer.vue";
import API from "@/API/auth.js";

export default {
  props: {},
  components: { NavigationDrawer, AccountNavDrawer },
  data: () => ({
    dialog: false,
    drawer: false,
    accountDrawer: false,
    menu: false,
    hover: false,
    tabIndex: -1,
    companyInfo: [
      {
        title: "ourMission",
        link: "AboutUs",
        externalLink:
          "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
      },
      {
        title: "investment",
        link: "",
        externalLink:
          "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
      },
      {
        title: "brand",
        link: "",
        externalLink:
          "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
      },
    ],
    contents: [
      {
        title: "make",
        link: "Service",
        subContent: [
          { title: "makeAbout", link: "Service" },
          { title: "makeOrder", link: "Order" },
          { title: "makePortfolio", link: "Service", id: "portfolio" },
        ],
      },
      {
        title: "printer",
        link: "Products",
        subContent: [
          { title: "products", link: "Products" },
          { title: "documents", link: "Products", id: "document" },
        ],
      },
      {
        title: "edu",
        link: "Education",
        subContent: [
          { title: "eduAbout", link: "Education" },
          { title: "eduContents", link: "Education", id: "contents" },
          { title: "eduPortfolio", link: "Education", id: "case" },
        ],
      },
      {
        title: "makerSpace",
        link: "",
        subContent: [
          { title: "aboutSpace", link: "" },
          { title: "spaceInfo", link: "" },
        ],
      },
      {
        title: "madeAll",
        link: "AboutUs",
        subContent: [
          {
            title: "goals",
            externalLink:
              "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
          },
          {
            title: "investment",
            externalLink:
              "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
          },
          {
            title: "brandResource",
            externalLink:
              "https://docs.madeall3d.com/fa4605bf-8fd9-4bfc-9a9f-88c85478f01d",
          },
        ],
      },
    ],
  }),
  computed: {
    logoSrc() {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        return require("@/assets/icons/MadeAll_Icon(Light).svg");
      }

      const language = this.$i18n.locale === "en" ? "en" : "ko";
      const theme = this.$vuetify.theme.dark ? "Dark" : "Light";
      return require(`@/assets/branding/Logo(${language})_${theme}.svg`);
    },
  },
  methods: {
    navigateTo(route, id) {
      if (this.$router.currentRoute.name !== route) {
        this.$router.push({ name: route }).then(() => {
          this.scrollToId(id);
        });
      } else {
        this.scrollToId(id);
      }
    },
    scrollToId(id) {
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        window.scrollTo(0, 0);
      }
    },
    handleClick(item) {
      if (item.externalLink) {
        window.open(item.externalLink);
      } else if (item.link) {
        this.navigateTo(item.link, item.id);
      } else {
        this.notReady();
      }
    },
    logout() {
      this.$store.dispatch("auth/user/SET_LOGOUT", "Home").then(() => {
        this.$toasted.global.notice("로그아웃 되었습니다");
        API.rebootChannelIo();
      });
    },
    notReady() {
      alert("준비중입니다.");
    },
    openSignIn() {
      this.$parent.openSignIn();
    },
    appBarChanged(val) {
      if (val == false) {
        this.menu = val;
      }
    },
  },
};
</script>
