<template>
  <div>
    <v-navigation-drawer
      v-model="propDrawer"
      fixed
      color="white"
      right
      floating
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'"
      transition="fade-transition"
    >
      <div class="px-0 d-flex">
        <v-spacer />
        <v-icon
          color="onSurface_normal"
          class="ma-4"
          size="18"
          @click="$emit('update:drawer', false)"
        >
          mdi-close
        </v-icon>
      </div>

      <div class="mx-4">
        <div class="py-2">
          <span
            v-if="$store.getters['auth/user/GET_USER']"
            class="headline bold"
          >
            {{ $t("pageAppbar.welcome") }}
          </span>
          <span v-else class="headline bold">{{
            $t("pageAppbar.needLogin")
          }}</span>
        </div>

        <div
          v-if="$store.getters['auth/user/GET_USER']"
          class="d-flex align-center mt-4 mb-10"
        >
          <v-avatar
            size="20"
            color="black"
            class="my-auto mr-3"
            style="border: 1px solid var(--v-black-base)"
          >
            <v-img :src="$store.getters['auth/user/GET_USER'].photoURL" />
          </v-avatar>
          <span class="onSurface_dark--text title-2 regular"
            >{{ $store.getters["auth/user/GET_USER"].displayName }} 님</span
          >
        </div>

        <div
          v-else
          class="d-flex align-center mt-4 mb-10 title-2 regular onSurface_dark--text"
        >
          <span class="pa-0 hover-pointer" @click="routerPush('Login')">
            {{ $t("pageAppbar.login") }}
          </span>
        </div>
      </div>

      <div
        v-if="$store.getters['auth/user/GET_USER']"
        class="d-flex my-6 mx-4"
        style="flex-direction: column; gap: 12px"
      >
        <div
          class="d-flex align-center hover-pointer"
          style="min-height: 30px"
          @click="routerPush('MyPage')"
        >
          <feather type="user" size="18" class="mr-3" color="onSurface_light" />
          <span class="py-0 onSurface_dark--text">{{
            $t("pageAppbar.myPage")
          }}</span>
        </div>
        <div
          class="d-flex align-center hover-pointer"
          style="min-height: 30px"
          @click="routerPush('MyPage')"
        >
          <feather type="edit" size="18" class="mr-3" color="onSurface_light" />
          <span class="py-0 onSurface_dark--text">{{
            $t("pageAppbar.myMakerSpace")
          }}</span>
        </div>
        <div
          class="d-flex align-center hover-pointer"
          style="min-height: 30px"
          @click="logout()"
        >
          <feather
            type="log-out"
            size="18"
            class="mr-3"
            color="onSurface_light"
          />
          <span class="py-0 onSurface_dark--text">{{
            $t("pageAppbar.logout")
          }}</span>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import API from "@/API/auth.js";
export default {
  props: {
    propDrawer: Boolean,
    contents: Array,
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
    routerPush(routerName) {
      if (routerName === "") {
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0))
          .then(() => {
            this.$emit("update:drawer", false);
          });
      }
    },
    logout() {
      this.$store.dispatch("auth/user/SET_LOGOUT", "Home").then(() => {
        API.rebootChannelIo();
        this.$emit("update:drawer", false);
      });
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style scoped>
.hover-pointer:hover {
  cursor: pointer;
}
</style>
