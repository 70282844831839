<template>
  <v-container fluid class="pa-0 d-flex-column">
    <page-appbar />
    <v-container
      fluid
      class="background pb-15 px-0 pt-0"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 60px' : 'margin-top: 52px;'
      "
    >
      <v-img
        style="align-items: center"
        :height="$vuetify.breakpoint.mdAndUp ? '640' : '180'"
        :src="
          $vuetify.breakpoint.mdAndUp
            ? require('@/assets/images/404.png')
            : require('@/assets/images/404Mobile.png')
        "
      >
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-row style="max-width: 1080px" class="mx-auto">
            <v-col align-self="center" class="" cols="12">
              <v-card width="520" class="transparent ml-auto" dark flat>
                <v-card-title class="h1 bold light-blue--text text--lighten-2">
                  OOPS!
                </v-card-title>
                <v-card-subtitle class="h2 bold white--text">
                  페이지를 찾을 수 없습니다.
                </v-card-subtitle>
                <v-card-text class="white--text subtitle-1">
                  페이지의 주소가 잘못 입력되었거나,<br />
                  주소의 변경으로 인해 접속하실 수 없습니다.<br />
                  URL 주소를 다시 한번 확인해 주세요.
                </v-card-text>
                <v-card-actions class="justify-left">
                  <v-btn
                    color="primary"
                    height="40"
                    width="100"
                    class="title bold mx-2"
                    depressed
                    @click="back()"
                  >
                    돌아가기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-img>
      <div v-if="$vuetify.breakpoint.mdAndDown">
        <v-card width="100%" class="transparent ml-auto pt-3" dark flat>
          <v-card-title
            class="justify-center h2 bold light-blue--text text--lighten-2"
          >
            OOPS!
          </v-card-title>
          <v-card-subtitle align="center" class="title-1 bold black--text">
            페이지를 찾을 수 없습니다.
          </v-card-subtitle>
          <v-card-text align="center" class="subtitle-1 black--text">
            페이지의 주소가 잘못 입력되었거나,<br />
            주소의 변경으로 인해 접속하실 수 없습니다.<br />
            URL 주소를 다시 한번 확인해 주세요.
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              class="title-1 mx-3 px-6"
              large
              depressed
              @click="back()"
            >
              돌아가기
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-container>
    <page-footer />
  </v-container>
</template>

<script>
import setMeta from "@/utils/setMeta";
import PageAppbar from "../../components/Appbar/PageAppbar.vue";
import PageFooter from "@/components/Footer/PageFooter.vue";
export default {
  components: {
    PageAppbar,
    PageFooter,
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  mounted() {
    setMeta({ title: "404", description: "존재하지 않는 페이지입니다." });
  },
};
</script>

<style></style>
